.pinball {
  height: 65vh;
  opacity: 0;
}

.entry-bg {
  animation: fade linear 3s infinite;
}

@media (min-aspect-ratio: 19/10) {
  .tunnel {
    width: 100%;
    height: auto;
  }
}
