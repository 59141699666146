.anim-container {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: fixed;
  z-index: 25;
  transition: 1s;
}

.hide {
  opacity: 0;
  pointer-events: none;
}

.half-fade {
  animation: fade linear 3s infinite;
}

.half-fade-delay-1 {
  animation: fade linear 3s infinite;
  animation-delay: 1s;
}

.half-fade-delay-2 {
  animation: fade linear 3s infinite;
  animation-delay: 2s;
}

.spin {
  animation: spin 4s linear infinite;
  transform-origin: center center;
}

#center-thing {
  z-index: 15;
  width: 10%;
}
