.footer {
  position: relative;
  background-color: white;
}
.footer.black-mode {
  background-color: black;
  color: white;
}
.footer-main {
  padding: 20px;
}
.footer-content {
  display: flex;
  align-items: flex-end;
  padding: 100px;
}
.footer-contact {
  flex: 2;
}
.footer-social {
  flex: 1;
}
.footer-deco {
  width: 100%;
}
.footer-qrcode {
  width: 100px;
  margin-bottom: 20px;
}
.footer-email {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.footer-text {
  font-size: 20px;
  font-weight: 500;
}
.footer-arrow {
  width: 40px;
  margin: 0px 10px 0px 20px;
}
.footer-email-title {
  display: flex;
  align-items: center;
}
.footer-social-row {
  display: flex;
  margin-top: 20px;
}
.footer-social-icon {
  width: 14px;
  margin-right: 10px;
}
.footer-copyright {
  display: flex;
  justify-content: space-around;
  padding: 0 20px;
  background-color: black;
  color: white;
}
.footer-copyright.black-mode {
  background-color: white;
  color: black;
}
.copyright-text {
  font-size: 10px;
}
@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
  }
  .footer-deco,
  .footer-qrcode {
    display: none;
  }
  .footer-email {
    flex-direction: column;
    align-items: flex-start;
  }
  .footer-arrow {
    transform: rotate(90deg);
    margin-left: 10px;
    margin-top: 10px;
    width: 30px;
  }
  .footer-contact,
  .footer-social {
    padding: 20px 0;
  }
  .footer-social-row {
    margin-top: 0px;
    font-size: 14px;
  }
  a.footer-text {
    font-size: 16px;
  }
  .footer-copyright {
    justify-content: flex-start;
  }
  .copyright-text:nth-child(2) {
    display: none;
  }
}
@media (max-width: 1140px) {
  .copyright-text:nth-child(1) {
    display: none;
  }
}
