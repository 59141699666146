.home-canvas {
  position: relative;
  height: 100vh;
  width: 100%;
}
.home-canvas > canvas {
  position: relative;
}

.home-canvas:after {
  position: absolute;
  bottom: 0;
  content: "";
  height: 50px;
  width: 100%;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  );
}

.home-news {
  padding: 10px 30px 0;
  margin: auto;
  background-color: white;
}

.news-banner {
  position: relative;
  min-height: 580px;
  background-color: black;
  border-top-left-radius: 80px;
  border-top-right-radius: 80px;
}

.news-tag {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 320px;
  height: 244px;
  padding: 40px 20px 20px;
  position: absolute;
  bottom: 40px;
  right: 40px;
}

@media (max-width: 764px) {
  .home-news {
    padding: 10px;
  }
  .news-tag {
    height: 160px;
    padding: 20px 20px 0px;
    right: 50%;
    transform: translateX(50%);
  }
  .marquee .news-title {
    font-size: 45px;
    line-height: 50px;
  }
}

.news-title {
  font-size: 80px;
  line-height: 74px;
  font-weight: 500;
  white-space: nowrap;
}

.news-content {
  display: flex;
  margin-top: 30px;
  background-color: #e6e6e6;
}

/* SECOND NEWS */
.second-news {
  flex: 1;
  background-color: white;
  overflow: hidden;
}

.second-news .news-image {
  position: relative;
  background-color: black;
  height: 720px;
  margin-top: 20px;
}
/* SECOND NEWS */

/* DESKTOP ONLY NEWS */
.desktop-only-news {
  flex: 2;
  margin-left: 2%;
  overflow: hidden;
}

@media (max-width: 920px) {
  .desktop-only-news {
    display: none;
  }
}

.desktop-only-news .top {
  margin-bottom: 2%;
  position: relative;
  height: 38%;
  background-color: black;
}

.desktop-only-news .bottom {
  height: 50%;
}

.desktop-only-news .bottom .news-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
}

.desktop-only-news .bottom .news-image {
  position: relative;
}

.desktop-only-news .bottom .news-image iframe {
  width: calc(100% - 13rem);
}
/* DESKTOP ONLY NEWS */
