.slick-dots {
  width: 80% !important;
  bottom: -40px !important;
  left: 50%;
  transform: translateX(-50%);
}
.slick-dots li {
  margin: 0 15px !important;
}

.script-arrow {
  margin-top: 14px;
  cursor: pointer;
}

.carousel-bg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (max-width: 414px) {
  .slick-dots {
    text-align: start;
    bottom: -30px !important;
  }
  .slick-dots li {
    margin: 0 10px !important;
  }
  .script-arrow {
    margin-top: 4px;
  }
}
