/* profile modal */
.modal-title,
.profile-modal-title {
  font-size: 20px;
  word-break: break-all;
}
.info-row {
  word-break: break-all;
}
.policy-link {
  color: rgba(189, 152, 46, 1);
  text-decoration: underline;
}
.profile-modal-header {
  color: rgba(153, 153, 153, 1);
  margin-top: 12px;
  margin-bottom: 20px;
}
.profile-form {
  margin-top: -20px;
}
.modal-input-title {
  color: rgba(204, 204, 204, 1);
  margin: 20px 0 8px 0px;
}
.modal-input {
  width: 100%;
  font-size: 14px;
  padding: 12px;
  border: 1px solid #666666;
  border-radius: 4px;
  background-color: transparent;
}
.modal-input::placeholder {
  color: rgba(102, 102, 102, 1);
}
.info-row h4 {
  font-size: 16px;
  margin-bottom: 20px;
  color: rgba(102, 102, 102, 1);
}
.info-row p {
  font-size: 14px;
  margin-bottom: 36px;
  color: white;
}

.scrolling-wrapper {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
}

/* inputs */
.select-placeholder {
  color: rgba(102, 102, 102, 1);
}

.slick-track {
  display: flex !important;
}
.slick-track .slick-slide {
  display: flex !important;
  height: auto;
  align-items: center;
  justify-content: center;
}
