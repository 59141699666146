.marquee {
  display: flex;
  overflow: hidden;
}

.marquee-item {
  animation: to-left 5s linear infinite;
}

@keyframes to-left {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}
