.instrument-deco {
  position: absolute;
  pointer-events: none;
}
.instrument-deco:nth-child(1),
.instrument-deco:nth-child(3) {
  display: none;
}
.instrument-deco:nth-child(2) {
  top: 75px;
  left: 50%;
  transform: translateX(-50%);
  width: 95%;
  height: 500px;
}

@media (min-width: 996px) {
  .instrument-deco:nth-child(1) {
    display: block;
    top: 100px;
    left: 2.5%;
    width: 20%;
  }
  .instrument-deco:nth-child(2) {
    top: 100px;
    width: 60%;
    height: auto;
  }
  .instrument-deco:nth-child(3) {
    display: block;
    top: 110px;
    width: 22%;
    right: 2.5%;
  }
}

@media (min-width: 1150px) {
  .instrument-deco:nth-child(1) {
    top: 75px;
  }
  .instrument-deco:nth-child(2) {
    top: 75px;
  }
  .instrument-deco:nth-child(3) {
    top: 85px;
  }
}
