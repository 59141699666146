.about {
  color: white;
  text-align: center;
}

.about-members {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  position: relative;
  padding: 100px 20px;
  overflow: hidden;
}
.about-members-bg {
  position: absolute;
  top: 30px;
  width: calc(100vw - 20px);
  z-index: -1;
}
.about-member {
  cursor: pointer;
}

.intro-title {
  width: 100%;
  position: relative;
  font-size: 50px;
}

.intro-title::before,
.intro-title::after {
  position: absolute;
  content: "";
  display: block;
  width: 80px;
  height: 80px;
  background-color: white;
  border-radius: 50%;
}

.intro-title::before {
  top: 0;
  left: 50px;
}

.intro-title::after {
  top: 0;
  right: 50px;
}

.intro-name {
  display: inline-block;
  margin: auto;
  position: relative;
  color: white;
  font-size: 45px;
}

.intro-name > span {
  display: inline-block;
}

.about-title {
  margin-top: 16px;
}

.name-deco-text {
  font-size: 16px;
  font-weight: 500;
}

.name-deco-text:nth-child(1) {
  text-align: right;
  padding-right: 20px;
}

.name-deco-text:nth-last-child(1) {
  text-align: left;
  padding-left: 20px;
}

.intro-sec {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 0px 80px 80px;
}

.intro-text-sec {
  position: relative;
  padding: 140px 100px;
}

.intro-text-top-deco {
  position: absolute;
  width: 45px;
  top: 12%;
  right: 9%;
}

.intro-text-bottom-deco {
  position: absolute;
  width: 44px;
  bottom: 2%;
  left: 28%;
}

.intro-rights-sec {
  margin-top: 40px;
}

.image-sec {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.intro-image-deco {
  position: absolute;
  top: 9%;
  left: 1%;
}

.intro-image-bg {
  position: relative;
  width: 26.3vw;
}

.intro-image {
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
}

.intro-image-outline {
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
}

.weapon-sec {
  display: flex;
  flex-direction: column;
  padding: 60px 80px 0px 0px;
}
.weapon-row {
  display: flex;
}
.weapon-col {
  position: relative;
  flex: 1;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.weapon-title {
  position: relative;
  padding: 10px;
  z-index: 1;
}
.weapon-title-bg {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
.weapon-title-bg.reverse {
  transform: translateX(-50%) rotate(180deg);
}

.weapon-skill-deco {
  position: absolute;
  top: -3%;
  right: 10%;
}

.weapon-equip-deco {
  position: absolute;
  bottom: -2%;
  right: 20%;
  width: 24px;
}

.weapon-content {
  padding: 20px;
  font-size: 14px;
}

.weapon-img {
  max-width: 240px;
}

.member-intro-sec {
  position: relative;
}

.about-intro-bg {
  position: absolute;
  top: -360px;
  z-index: -1;
}
