.login-button-wrapper:nth-child(1) {
  margin-bottom: 16px;
}
.login-button-wrapper {
  position: relative;
}
.login-button {
  width: 320px;
  height: 56px;
  background-color: white;
  border-radius: 28px;
  position: absolute;
  z-index: 1;
  pointer-events: none;
}
.login-button-text > span {
  text-transform: capitalize;
}
.login-icon {
  width: 24px;
  height: 24px;
  margin-right: 12px;
  pointer-events: none;
}

/* google */
.google > button {
  width: 320px;
  height: 56px;
  background-color: white;
  border-radius: 28px !important;
}
.google > button:hover {
  opacity: 1;
}
.google > button > div {
  display: none;
}

/* facebook */
.kep-login-facebook.metro {
  height: 56px;
  width: 320px;
  color: black;
  background-color: white;
  border-radius: 28px !important;
}
