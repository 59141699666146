@import-normalize;
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&family=Noto+Sans+TC:wght@400;500;700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("./about.css");
@import url("./entry.css");
@import url("./footer.css");
@import url("./history.css");
@import url("./home.css");
@import url("./instrument.css");
@import url("./video.css");
@import url("./login.css");
@import url("./marquee.css");
@import url("./modal.css");
@import url("./navbar.css");
@import url("./toastify.css");
@import url("./zIndex.css");
@import url("./transition.css");
@import url("./viproom.css");
@import url("./discography.css");

body {
  margin: 0;
  font-family: "Montserrat", sans-serif, "Noto Sans TC", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: black;
}

html {
  scroll-behavior: smooth;
}

p {
  font-weight: 400;
}
