.navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}
.navbar.background.white-mode {
  background-color: white;
  color: black;
}
.navbar.background.black-mode {
  background-color: black;
  color: white;
}
.navbar.white-mode .line {
  background-color: black;
}
.navbar.black-mode .line {
  background-color: white;
}
.desktop-navbar-logo {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
.mobile-navbar-logo {
  width: 44px;
  height: 44px;
  border-radius: 50%;
}
.desktop-navbar-items {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 70%;
}
.lang-toggler {
  position: relative;
  width: 50px;
  background-color: black;
  padding: 15px 28px 15px 30px;
  border: 1px solid black;
  border-radius: 15px;
}
.lang-toggler:hover {
  cursor: pointer;
}
.toggler-container {
  top: 50%;
  transform: translateY(-50%);
}
.lang-text {
  position: absolute;
  color: white;
  font-size: 10px;
  top: 50%;
  transform: translateY(-50%);
}
.lang-chi {
  right: 8px;
}
.lang-eng {
  left: 8px;
}
.toggler-circle {
  position: absolute;
  width: 30px;
  height: 30px;
  background-color: white;
  border-radius: 14px;
  top: 50%;
  transform: translateY(-50%);
  transition: 0.3s;
}
.circle-zh {
  right: 0;
}
.circle-en {
  right: calc(100% - 26px - 4px);
}
.black-line {
  border: 1px solid black;
}
.white-line {
  border: 1px solid white;
}
.mobile-nav-modal {
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 15;
}
.white-mode.mobile-nav-modal {
  background-color: white;
}
.black-mode.mobile-nav-modal {
  background-color: black;
}
.mobile-nav-items {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.mobile-nav-item {
  margin: 10px auto;
}
.nav-button {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.button-line-container {
  position: relative;
  width: 24px;
  height: 10px;
}
.button-line-container > .line {
  position: absolute;
  width: 24px;
  height: 2px;
  transition: 0.3s;
}
.button-line-container > .line:nth-child(1) {
  top: 0;
}
.button-line-container > .line:nth-child(2) {
  bottom: 0;
}
.button-line-container > .close:nth-child(1) {
  transform: rotate(45deg) translateY(5px);
}
.button-line-container > .close:nth-child(2) {
  transform: rotate(-45deg) translateY(-5px);
}

.gear-icon {
  width: 30px;
  cursor: pointer;
}
.gear-icon:hover {
  animation: rotate 2s linear infinite;
}

.rotate {
  transform-origin: center;
  transform-box: fill-box;
  animation: rotate 10s linear infinite;
}
@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
