.control-player-1 {
  transform: perspective(10cm) rotateY(8deg);
}

.control-player-3 {
  transform: perspective(10cm) rotateY(-8deg);
}

img.control-player-1,
.control-player-2 iframe,
img.control-player-3,
.control-player-mobile iframe {
  border-radius: 1rem;
}

#pc_prev_video_button:hover > #prev_video_button_bg {
  fill: url(#N);
}

#pc_next_video_button:hover > #next_video_button_bg {
  fill: url(#N);
}

#pc_play_button:hover #pc_play_button_center {
  fill: url(#play_button_color2);
}

#pc_play_button:hover #pc_play_button_bg {
  fill: url(#play_button_color1);
}

.controller-title-deco {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.controller-title-deco:nth-child(1) {
  left: 4px;
}
.controller-title-deco:nth-child(2) {
  right: 4px;
}
.controller-desc {
  font-size: 12px;
  width: 250px;
  margin-top: 20px;
}
.controller-large-image {
  border-radius: 1.5rem;
}
.controller-small-screen {
  position: absolute;
  left: 35px;
  bottom: 24px;
}

.controller-small-image {
  position: absolute;
  top: 20px;
  z-index: 0;
}
.controller-button {
  position: absolute;
  right: 20px;
  bottom: 74px;
}

.video-bg {
  width: 337.5px;
}
